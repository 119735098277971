import React from 'react'
import { Heading } from 'theme-ui'
import Divider from '@components/Divider'
import { Box, Text, Card, Flex, Button } from 'theme-ui'
import Section from '@components/Section'
import { Link } from 'gatsby'

/**
 * Shadow me to add your own content
 */

const styles = {
  headingXXX: {
    background: t => `
      linear-gradient(
        45deg,
        ${t.colors.alpha} 32.5%,
        ${t.colors.betaDark} 50.5%)
    `,
    color: `beta`,
    WebkitBackgroundClip: `text`,
    WebkitTextFillColor: `transparent`,
    fontWeight: `bold`
  },
  heading: {
    color: `#5f7b34`,
    fontWeight: `bold`
  },
  running: {
    color: `omegaDark`,
    fontWeight: `normal`,
    maxWidth: [`full`, null, `2/3`]
  },
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, null, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, null, `1/2`]
  },
  h3: {
    margin: 0
  }
}

export default () => (
  <>
    <Divider space={3} />
    <Heading variant='h1'>
      Women’s Institute - Mildenhall
    </Heading>
    <Divider space={3} />
    <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Section title=''>
                <Text variant='p'>
                  After 7 years we have decided to suspend the Mildenhall WI meetings and form our own independent group called the Ladybirds. We will continue to meet on the 3rd Tuesday each month at 10:00am. All are welcome
                  {/* We are a new enthusiastic and vibrant group formed in June 2017.  All ages and backgrounds are welcome.  Our aim is to be a modern WI, to have fun and meet new local people, create groups with common interests to share, for example art, music, walking etc., to learn about new topics and get involved in local projects as well as supporting the West Suffolk WI Federation and WI National on wider issues.
                  <br/><br/>
                  Anyone who is interested in joining may attend as a visitor free of charge for the first meeting, two further chargeable visits are allowed thereafter you will be expected to join. Subscription rate for joining, for a whole year, is £46.00 payable in April.
                  <br/><br/>
                  We meet on the 3rd Tuesday each month at 10:00am.
                  <br/><br/>
                  Car parking is available in the lower car park near the river/Jubilee fields.
                  <br/><br/>
                  We have a hearing loop. */}
                </Text>
              </Section>
            </Box>
            
      </Flex>
  </>
)
